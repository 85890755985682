
import { mapMutations, mapState } from 'vuex'
export default {
  validate ({ params }) {
    // Must be a number
    return /^\d+$/.test(params.id)
  },
  async asyncData ({ params, $axios, $cookies, redirect }) {
    try {
      const result = await $axios.$get(`orders/${params.id}`)
      // console.log('debug credit-card-finished :: ', result)
      let resultMapping = null
      if (result.data && typeof result.data.products !== 'undefined') {
        resultMapping = await $axios.$get('mapping_inputs', {
          params: { mapping_pattern_id: result.data.products[0].mapping_input_id }
        })
      }

      // if (result.data.payment_type > 0) {
      //   redirect('/my/orders/' + params.id)
      // }
      return {
        orderID: params.id,
        totalResult: result.data,
        packagingTypeId: (resultMapping != null && result.data.products[0].mapping_input_id) ? resultMapping.data.mappingInput.packaging_type_id : 4,
        // orderCode: params.order_code,
        orderCode: result.data.order_code,
        orderDetail: result.data,
        mappingInputObject: (resultMapping != null) ? resultMapping.data : null
      }
    } catch (error) {
      console.log('error credit-card-finished', error.message)
    }
  },
  data () {
    return {
      colorText: {
        1: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        2: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        3: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 1 สี',
          4: 'พิมพ์ 4 สี เสมือนจริง'
        },
        5: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        }
      }
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: 'purchase',
        path: `${this.$route.path}`,
        title: 'credit-card-finished'
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
    this.hideLoading()
    // console.log(this.totalResult)
    // console.log(this.mappingInputObject)
    // console.log(JSON.parse(this.totalResult.products[0].option_json))
    // console.log(JSON.parse(this.totalResult.products[0].sme_json))
    this.sendDataLayer()
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    sendDataLayer () {
      if (localStorage.getItem('credit-card-finished-send-' + this.orderID) === this.orderID) {
        return false
      }
      localStorage.setItem('credit-card-finished-send-' + this.orderID, this.orderID)
      try {
        const pkgType = this.mappingInputObject.mappingInput.packaging_type_id
        const csData = JSON.parse(this.totalResult.products[0].option_json)
        const smeData = JSON.parse(this.totalResult.products[0].sme_json)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ ecommerce: null })
        let dimension2 = ''
        if (this.totalResult.is_brief_design === 1) {
          dimension2 = 'จ้างออกแบบ'
        } else if (this.totalResult.design_by_yourself === 1) {
          dimension2 = 'ออกแบบออนไลน์'
        } else {
          dimension2 = 'ออกแบบเอง'
        }
        let dimension4 = ''
        const productData = this.totalResult.products_all[0].product
        if (productData.h) {
          dimension4 = `${productData.w / 10} x ${productData.l / 10} x ${productData.h / 10}`
        } else {
          dimension4 = `${productData.w / 10} x ${productData.l / 10}`
        }
        let paymentMethod = ''
        if (parseInt(this.totalResult.trans_2c2p_creditcard_id) > 0) {
          paymentMethod = 'บัตรเครดิต/เดบิต'
        } else if (parseInt(this.totalResult.trans_2c2p_online_id) > 0) {
          paymentMethod = 'Online Banking'
        } else {
          paymentMethod = '2c2p'
        }
        const withVat = this.totalResult.total * (1 + (7 / 100))
        const vatPrice = withVat - this.totalResult.total
        let totalWithoutDiscount = this.totalResult.total_without_discount
        if (this.totalResult.total_without_discount === null || typeof this.totalResult.total_without_discount === 'undefined') {
          totalWithoutDiscount = this.totalResult.total
        }
        this.cardPaperActiveName = smeData.cardPaperActiveName
        // console.log('purchase', this.totalResult, smeData, csData)
        window.dataLayer.push({
          event: 'purchase',
          paymentMethod,
          discount: totalWithoutDiscount - this.totalResult.total,
          mold: this.totalResult.print_plate_price,
          dieCut: this.totalResult.plate_diecut_price,
          moldCost: this.totalResult.print_plate_price,
          dieCutCost: this.totalResult.plate_diecut_price,
          creditCardFee: ((parseInt(this.totalResult.trans_2c2p_creditcard_id) > 0 || parseInt(this.totalResult.trans_2c2p_online_id) > 0) ? this.calCostFor2C2P(this.totalResult.total, this.totalResult.products[0].amount) : 0),
          designFee: this.totalResult.sum_design_price,
          shippingCost: this.totalResult.shipping_price,
          ecommerce: {
            purchase: {
              actionField: {
                id: this.totalResult.id,
                revenue: parseFloat(this.totalResult.total).toFixed(2),
                tax: parseFloat(vatPrice).toFixed(2),
                shipping: this.totalResult.shipping_price,
                coupon: smeData.promotion_code
              },
              products: [{
                id: `LOCOPACK_${pkgType}` + this.totalResult.products[0].mapping_input_id.toString().padStart(9, '0'),
                name: smeData.mapping_inputs.name_th,
                price: (typeof this.totalResult.price_per_pcs !== 'undefined' ? this.totalResult.price_per_pcs : ''),
                category: this.$pkgTypeThaiName(pkgType),
                quantity: this.totalResult.amount,
                discount: ((csData.discount) ? csData.discount.toFixed(2) : 0),
                brand: 'Locopack',
                dimension1: (typeof this.totalResult.products[0] !== 'undefined' ? 'โรงงาน ' + this.totalResult.products[0].factory_id : ''),
                dimension2,
                dimension3: this.mappingInputObject.mappingInput.name_th,
                dimension4,
                dimension5: this.cardPaperActiveName,
                dimension6: (('gsm' in csData.cs_data) ? csData.cs_data.gsm : csData.cs_data[0].paper_gsm + ''),
                dimension7: (('zipcode' in csData.cs_data) ? csData.cs_data.zipcode : csData.cs_data[0].zipcode),
                dimension8: this.colorText[pkgType][smeData.color],
                dimension9: (this.totalResult.products[0].plate_text + '').replace('พื้นที่', ''),
                dimension10: '',
                dimension11: '',
                dimension12: csData.delivery_by,
                dimension13: this.totalResult.products[0].production_lead_time
              }]
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  }
}
